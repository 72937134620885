@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.sticky-nav-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 2;

}

.logo {
  align-self: center;
  height: 50px;
 margin-left: 25%;
}

.sticky-nav-links {
  display: flex;
  align-items: center;
  color: rgb(90, 113, 159);
  padding-right: 25%;
}

.sticky-nav-links a {
  text-decoration: underline;
  color: inherit;
  margin-right: 10%;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  text-underline-offset: 5px;
  text-transform: uppercase;
  transform: skew(-9deg);
}


.sticky-nav-links a:hover,
.sticky-nav-links a:focus {
  color: rgb(176, 180, 190);

}

.sticky-project {
  cursor: pointer;
}
