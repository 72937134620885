@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.page-two-background{
  width: 100%;
  height: auto;
}


.carousel-app {
  position: absolute;
  width: 55%;
  top: 1145px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Lato", sans-serif;
  transform: translateX(-50%);
}
