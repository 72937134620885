@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.navigation-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  z-index: 1;
  top: 10;
  height: 50px;

}

.page-links{
  display: flex;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  align-items: center;
  font-size: large;
  margin-right: 12%;
}

.social-links{
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;

}

.stryker-icon{
  height: 50px;
  padding: 2px;
  margin-left: 20px;
  cursor: pointer;
}

.linkedin,
.github {
  margin-right: 5px;
}

.projects-link,
.contact-link,
.resume-link {
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.resume-link{
  padding-right: 0px;
}

.linkedin-icon{
  background-color: lightblue;
  height: 40px;
  padding: 2px;
  border-radius: 10px;
}

.github-icon{
  background-color: orange;
  height: 40px;
  padding: 2px;
  border-radius: 10px;
}

.hidden {
  display: none;
}

.tab-icon {
  height: 20px;
  background-color:plum;
  border-radius: 5px;
  cursor: pointer;
}
