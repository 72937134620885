@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.carousel-photo {
  border-radius: 10px;
}

.project-description {
  color: white;
  flex: 1;
  max-width: 70%;
  text-align: left;
  font-family: 'lato', sans-serif;
}

.project-name {
  color: orange;
  margin-top: 20px;
  font-family: 'lato', sans-serif;
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel-module {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.carousel .control-dots {
  position: absolute;
  padding-top: 30px;
  text-align: center;
  width: 100%;
  z-index: 1;
  display: none;
}

.git-link{
  align-items: left;
  font-family: 'lato', sans-serif;
  font-weight: 700;
}

.git-link a {
  color: grey;
}

.project-stack-container{
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 margin: 10;
 padding-top: 20px;
}

.stack-icon-photo{
  height: 60px;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.5);
}
