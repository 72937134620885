@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.footer-container{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: left;
  padding-left: 25px;
  align-items: center;
  color: white;
  background-color: grey;
  opacity: .2;
  font-family: "Lato", sans-serif;
}

.back-to-top {
  justify-content: left;
  cursor: pointer;
  padding-bottom: 5px;
  font-family: "Lato", sans-serif;
}
