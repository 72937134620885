body,html {
  margin: 0;
  padding: 0;
  background-color: rgb(20, 18, 36);
}



.App {
  text-align: center;

}
.navigation{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.page-two{
  margin-top: -4px;
}

.page-three{
  margin-top: -4px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
