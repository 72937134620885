@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.contact-form {
  text-align: center;
  border-radius: 15px;
  padding: 20px;
  max-width: 400px;

}
.custom-input-field {
  color: white;
  background-color: rgb(246, 243, 243, .066);
  font-weight: 500;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
}

.ui.form .field > label,
.custom-input-field label {
  color: inherit !important;
  font-family: "Lato", sans-serif;

}

.MuiInputBase-root {
  color: black;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  background-color:rgb(255, 255, 255, .4);
  font-family: "Lato", sans-serif;
  min-width: 25%;
}


.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(5px, -15px) scale(0.45);
}


.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 3px solid #e6b800;
    pointer-events: none;
}

.form-textarea-control-opinion {
  padding-left: 5px;
}


.MuiFormLabel-root.Mui-focused {
  color: #f80000;
}

.ui.form .field > label, .custom-input-field label {
  padding-left: 5px;
}
