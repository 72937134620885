@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.biography-header{
  font-size: larger;
  font-weight: 700;
}

.biography-container{
  position: absolute;
  top: 45%;
  left: 70%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 10px;
  max-width: 50%;
  max-height: 60%;
  overflow: scroll;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
  text-align: left;
  font-family: "Lato", sans-serif;
  background-color: rgb(246, 243, 243, .066);
  border-radius: 25px;
}

.biography-container::-webkit-scrollbar {
  background-color: rgb(246, 243, 243, .066);
  width: 0;
  height: 0;
}

@media (max-width: 1100px) {
  .biography-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust the width to take the whole page */
    height: 70%; /* Adjust the height to take the whole page */
    max-width: none; /* Remove max-width constraint */
    max-height: none; /* Remove max-height constraint */
  }
}
