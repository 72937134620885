@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.page-one-container {
  position: relative;
  display: flex;
}

.biography-app{
  position: absolute;
  top: 45%;
  left: 70%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 10px;
  max-width: 50%;
  max-height: 60%;
  overflow: scroll;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  text-align: left;
  font-family: "Lato", sans-serif;
}


.page-one-background {
  width: 100%;
  height: auto;
}

.headshot-img {
  max-height: 50%;
  width: auto;
  position: absolute;
  top: 45%;
  left: 30%;
  transform: translate(-50%, -50%);
  border-radius: 30% 25px;
  outline: auto;
  outline-color: grey;
  outline-offset: 3px;
}

/* .headshot-img-container {
  padding-top: 100px;
} */

.test-img {
  max-height: 50px;
}
